<template>
  <div class="col-12 customDataTable">
    <div class="row list-entete">
      <div class="col-lg-7 col-md-6 col-sm-6">
        <div class="row">
          <div class="col-sm-8">
            <div class="form-material">
              <input
                class="form-control p-20"
                type="text"
                v-model="search"
                placeholder="Recherche"
              >
            </div>
          </div>
          <div class="col-sm-4">
            <select
              class="form-control"
              v-select
              :name="perpageId"
              :id="perpageId"
              v-model="userperapge"
            >
              <option
                v-for="(nb, index) in perpages"
                :value="nb"
                :key="index"
              >
                {{ nb }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-2">
        <a
          href="javascript:void(0)"
          @click.prevent="refresh"
          class="btn btn-third btn-round btn-sm"
          title="Actualiser"
        >
          <i class="icofont icofont-refresh" />
        </a>
      </div>
      <div class="col-lg-4 col-md-5 col-sm-4 text-right actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table
          class="table"
          :class="tableClass"
        >
          <thead>
            <tr>
              <td v-if="numerotation">
                #
              </td>
              <td
                v-for="(header, index) in headers"
                :key="index"
                v-if="visibleColum(index)"
                :style="header.style"
              >
                <sort
                  v-if="header.sortable"
                  :name="header.name"
                  :type="header.type"
                  :active="activeSort"
                  :index="index"
                  :function="header.function"
                  @sorting="sorting"
                >
                  {{ header.label }}
                </sort>
                <span v-else>{{ header.label }}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <!--<slot />-->
            <tr v-if="total === 0">
              <td
                :colspan="headers.length + 1"
                style="border-top: none; text-align: center;"
                class="alert alert-danger"
              >
                Aucune données chargées
                <!-- {{ $t('dataTable.list.empty') }} -->
              </td>
            </tr>
            <tr
              v-for="(d, index) in source"
              :key="index"
              v-if="visibleRow(index)"
            >
              <td v-if="numerotation">
                {{ index +1 }}
              </td>
              <data-cell
                v-for="(header, i) in headers"
                :key="i"
                v-if="visibleColum(i)"
                :type="header.type"
                :cell-data="d"
                :name="header.name"
                :rendering="(header.hasOwnProperty('template'))? header.template : null"
                :cell-component="header.hasOwnProperty('component')? header.component: null"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row pied-list">
      <div class="col-md-2">
        <span class="stat">Page: <strong>{{ currentPage }}/{{ pages }}</strong></span>
      </div>
      <div class="col-md-2">
        <span class="stat"><strong> {{ from + 1 }} - {{ offset }}/{{ total }}</strong></span>
      </div>
      <div class="col-md-8 navigation">
        <v-pagination
          v-model="currentPage"
          :page-count="pages"
          :classes="bootstrapPaginationClasses"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vPagination from 'vue-plain-pagination'
import { mapGetters, mapMutations } from 'vuex'
import { Select } from '../../directive/select'
import dataCell from './dataCell'
import sort from './sort'
import { LIBELLE_TYPE, COMPONENT_TYPE, NUMERIC_TYPE, DATE_TYPE, ORDER_ASC } from './dataType'
// import { Trans } from '../../plugins/Translation'
import { getToken, saveToken } from '../../plugins/localstorage'
import { PERPAGE_KEY } from '../../constants/app'
import moment from 'moment'
export default {
  name: 'Local',
  components: { vPagination, dataCell, sort },
  directives: { Select },
  props: {
    dataSource: { type: Array, required: true },
    headers: { type: Array, required: true },
    tableClass: { type: String },
    numerotation: { type: Boolean, default: true },
    id: {type: String, required: false}
  },
  watch: {
    userperapge () {
      this.setPerpage(this.userperapge)
      saveToken(PERPAGE_KEY, this.userperapge)
      this.currentPage = 1
    },
    currentPage () {
      this.from = this.perpage * (this.currentPage - 1)
      this.$emit('topage', this.currentPage)
    },
    
    search () {
      if (this.search === '') {
        this.$emit('search', this.search)
        this.source = this.dataSource
      }
      if (this.search.length > 2) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.source = [...this.dataSource].filter(item => this.$options.filters.deepSearch(item, this.search))
          this.$emit('search', this.search)
        }, 500)
        
      }
    },
    dataSource: {
      handler () {
        this.source = this.dataSource
      },
      deep: true
    }
  },
  beforeMount () {
    let perpage = parseInt(getToken(PERPAGE_KEY))
    if (perpage !== undefined && perpage !== null) this.userperapge = perpage
    else this.userperapge = this.perpage
    this.source = this.dataSource
    // console.log(this.userperapge)
  },
  data () {
    return {
      currentPage: 1,
      search: '',
      query: '',
      from: 0,
      userperapge: null,
      activeSort: null,
      timeout: null,
      source: []
    }
  },
  methods: {
    ...mapMutations({
      setParams:'options/SET_PARAMS',
      setPerpage: 'options/CHANGE_ITEMS_PERPAGE'
    }),
    // ...mapActions({
    //   setPerpage: 'options/changePerpage',
    //   setParams: 'options/setParams'
    // }),
    visibleColum (index) {
      let h = this.headers[index]
      if("visible" in h) return h.visible
      return true
    },
    visibleRow (index) {
      return !((index) < this.from) && !(index + 1 > this.offset)
    },

    sorting (payload) {
      this.activeSort = payload.index
      let type = payload.type
      let attribut = payload.by
      let order = payload.order
      switch (type) {
        case LIBELLE_TYPE:
          if (order === ORDER_ASC) {
            this.source = [...this.dataSource].sort((a, b) => a[attribut].localeCompare(b[attribut], this.userCurrentLang))
          } else {
            this.source = [...this.dataSource].sort((b, a) => a[attribut].localeCompare(b[attribut], this.userCurrentLang))
          }
          break
        case COMPONENT_TYPE:
          this.source = payload.function(order, this.dataSource)

          break
        case DATE_TYPE:
          if (order === ORDER_ASC) {
            this.source = [...this.dataSource].sort((a, b) => moment(a[attribut]).format("X") - moment(b[attribut]).format("X"))
          } else {
            this.source = [...this.dataSource].sort((b, a) => moment(a[attribut]).format("X") - moment(b[attribut]).format("X"))
          }
          break
        case NUMERIC_TYPE:
          if (order === ORDER_ASC) {
            this.source = [...this.dataSource].sort((a, b) => a[attribut] - b[attribut])
          } else {
            this.source = [...this.dataSource].sort((b, a) => a[attribut] - b[attribut])
          }
          break
        default:
          if (order === ORDER_ASC) {
            this.source = [...this.dataSource].sort((a, b) => a[attribut].localeCompare(b[attribut], this.userCurrentLang))
          } else {
            this.source = [...this.dataSource].sort((b, a) => a[attribut].localeCompare(b[attribut], this.userCurrentLang))
          }
      }
    },
    refresh () {
      this.$emit('refresh')
      this.source = this.dataSource
      this.currentPage = 1
      this.search = null
    }
  },
  computed: {
    ...mapGetters({
      perpage: 'options/itemsPerpage',
      params: 'options/params'
    }),
    bootstrapPaginationClasses () {
      return {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active-page',
        liDisable: 'disabled',
        button: 'page-link'
      }
    },
    perpageId(){
      if(this.id !== null) return this.id+'perpage'
      return 'perpage'
    },
    // userCurrentLang () {
    //   return Trans.getUserLang().langNoISO
    // },
    perpages () {
      return [5, 10, 15, 20, 25, 30, 50, 100]
    },
    pages () {
      if (this.total > 0) { return Math.ceil(this.total / this.perpage) }
      return 1
    },
    total () {
      if (this.source !== null && this.source instanceof Array) { return this.source.length }
      return 0
    },
    offset () {
      if (this.total > 0) {
        let result = this.from + this.perpage
        if (result > this.total) return this.total
        return result
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.active-page{
  a{
    background: #1c6100;
  }
    
}


    .customDataTable{
        font-size: 12px;
        .actions{
            a{
                margin: 0px 5px;
            }
        }
        table{
            thead{
                font-weight: bolder;
            }
        }
        .list-entete{
            margin-bottom: 20px;
            .form-material{
                input::placeholder{
                    font-size: 11px;
                }
            }
            a{
                font-size: 11px;
            }
            .stat{
                padding-top: 30px;
                font-size: 11px;

            }
        }
        .pied-list{
            position: relative;
            padding-bottom: 20px;
            .stat{
                font-size: 11px;
            }
            .pagination{
                position: absolute;
                right: 40px;
            }
        }
    }

</style>
